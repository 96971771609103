import {
  browserTracingIntegration,
  httpClientIntegration,
  init,
} from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./i18n";
import "./index.css";
import checkIsTicketMachine from "./lib/hooks/CheckIsTicketMachine.ts";

if (checkIsTicketMachine()) {
  init({
    dsn: "https://400188ab7b2b3063584e17943a1fd483@sentry.viadi-zero.ch/48",
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [browserTracingIntegration(), httpClientIntegration()],
    enabled: location.hostname !== "localhost", // disable sentry on localhost
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
