import { Config } from "@zvv-fkm/types";
import { useEffect, useMemo, useState } from "react";
import { PollOptions, usePollDepartures } from "./poll";
import { WSOptions, useWebSocketDepartures } from "./ws";

type FullOptions = {
  switchToPollDelayMS: number; // ms after the last pingpong that it should switch over to polling
  considerDeparturesAsOutdated: number; // max age in ms after which the system should no longer return outdated data (in case ws and poll fail) and instead return undefined
} & WSOptions &
  PollOptions;
export type Options = Partial<FullOptions>;

const defaultOptions: Options = {
  switchToPollDelayMS: 30 * 1000,
  considerDeparturesAsOutdated: 60 * 1000,
  pollingIntervalMS: 20 * 1000,
  wsReconnectBackoffBaseMS: 1000,
  wsReconnectBackoffMaxMS: 10 * 60 * 1000,
  wsReconnectMaxTries: 20,
  wsHeartbeatIntervalMS: 10 * 1000,
  wsHeartbeatTimeoutMS: 20 * 1000,
  wsHeartbeatMessageToServer: "CustomUBPing",
  wsHeartbeatMessageFromServer: "CustomUBPong",
};

export function useDepartures(
  config: Config,
  wsUrl: string,
  pollURL: string,
  pollApiKey: string,
  options?: Options,
) {
  const optionsWithDefaults = { ...defaultOptions, ...options } as FullOptions;

  const {
    departures: wsDepartures,
    departureReceivedTimestamp: wsReceivedTimestamp,
    lastPingPong,
  } = useWebSocketDepartures(config, wsUrl, optionsWithDefaults);

  // initially true s.t. we poll until we know that the websockets work
  const [enablePolling, setEnablePolling] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setEnablePolling(true);
    }, optionsWithDefaults.switchToPollDelayMS);
    return () => {
      setEnablePolling(false);
      clearTimeout(timeout);
    };
  }, [lastPingPong, setEnablePolling]);

  const {
    departures: pollDepartures,
    departureReceivedTimestamp: pollReceivedTimestamp,
  } = usePollDepartures(
    config,
    pollURL,
    pollApiKey,
    enablePolling,
    optionsWithDefaults,
  );

  const [isOutdated, setIsOutdated] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      console.error(
        "Both WS and Poll data too old, no longer providing departures. Will resume when new departures are received.",
      );
      setIsOutdated(true);
    }, optionsWithDefaults.considerDeparturesAsOutdated);
    return () => {
      setIsOutdated(false);
      clearTimeout(timeout);
    };
  }, [
    pollReceivedTimestamp,
    wsReceivedTimestamp,
    setIsOutdated,
    optionsWithDefaults.considerDeparturesAsOutdated,
  ]);

  const departures = useMemo(() => {
    if (isOutdated) {
      return undefined;
    }

    return pollReceivedTimestamp > wsReceivedTimestamp
      ? pollDepartures
      : wsDepartures;
  }, [
    wsReceivedTimestamp,
    pollReceivedTimestamp,
    wsDepartures,
    pollDepartures,
    isOutdated,
  ]);

  return {
    departures,
    wsReceivedTimestamp, // will be removed - only for ws test
    pollReceivedTimestamp, // will be removed - only for ws test
    wsDepartures, // will be removed - only for ws test
    pollDepartures, // will be removed - only for ws test
    wsLastPingPong: lastPingPong, // will be removed - only for ws test
    showingDeparturesFrom:
      pollReceivedTimestamp > wsReceivedTimestamp ? "Polling" : "WebSocket",
  };
}
