import { NotificationType } from "@zvv-fkm/types/api";
import { useMemo } from "react";
import "../../../..//assets/scss/_icons-ub.scss";
import "../../../../assets/scss/_icons-zvv.scss";

export const Icon = ({
  icon,
  className,
}: {
  icon: string;
  className?: string;
}) => {
  return <i className={`icon ${icon} ${className}`} />;
};
export const AlertIcon = (props: {
  notificationType: NotificationType | undefined;
  className?: string;
}) => {
  const icon: string = useMemo(() => {
    switch (props.notificationType) {
      case NotificationType.Construction:
        return " icon-ub-construction";
      case NotificationType.Disruption:
        return "icon-ub-disturbance";
      case NotificationType.Event:
        return "icon-ub-event";
      case NotificationType.Info:
        return "icon-ub-information";
      case NotificationType.REPLACEMENT_SERVICE:
        return "icon-ub-trainreplacementbus";
      default:
        return "icon-ub-information";
    }
  }, [props.notificationType]);

  return <Icon icon={icon} className={props.className} />;
};
