import { toZonedTime } from "date-fns-tz/toZonedTime";

export { Clock } from "./Clock";

export function LocalizedDate() {
  return toZonedTime(new Date(), "Europe/Zurich");
}

export const ClockType = {
  DIGITAL: "digital",
  ANALOG: "analog",
  NO_CLOCK: "noClock",
  NO_CLOCK_PLACEHOLDER: "noClockPlaceholder", // never set by the user, used internally for vertical split when subsequent screens shouldn't have a clock but the first one does
} as const;

export type ClockType = (typeof ClockType)[keyof typeof ClockType];
