import { DeparturesResponse } from "@zvv-fkm/types";
import { Ref, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import checkIsTicketMachine from "../lib/hooks/CheckIsTicketMachine";
import Header from "./Header";
import { getGridTemplateColumns } from "./table/gridTemplateColumns";
import Rows from "./table/rows/Rows";

export const Table = forwardRef(
  (
    {
      departures,
      showFootpath,
      multipleDirection,
      rowCount,
      intervalDepartureRow,
      stationSelected,
      debugMode,
    }: {
      departures: DeparturesResponse | undefined;
      showFootpath: boolean;
      multipleDirection: boolean;
      rowCount: number;
      intervalDepartureRow: boolean;
      stationSelected: boolean;
      debugMode: boolean;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const showPlatform = !!departures?.departures.some(
      (departure) => departure.platform != null,
    );

    const gridTemplateColumns = useMemo(() => {
      return getGridTemplateColumns(
        intervalDepartureRow,
        showPlatform,
        showFootpath,
        multipleDirection,
      );
    }, [intervalDepartureRow, showPlatform, showFootpath, multipleDirection]);

    return departures && departures.departures.length > 0 ? (
      <div ref={ref} className="relative flex flex-1 flex-col overflow-hidden">
        <div className="flex flex-1 flex-col overflow-hidden">
          <div
            className={`grid h-full overflow-hidden text-white [&_div>div:first-of-type]:!pl-[calc(2rem*var(--row-scaling))] [&_div>div:last-of-type]:!pr-[calc(2rem*var(--row-scaling))] [&_div>div]:items-center [&_div>div]:truncate [&_div>div]:px-3 ${checkIsTicketMachine() ? "[&_div>div]:py-1 " : " [&_div>div]:py-5"}`}
            style={{
              gridTemplateRows: `min-content repeat(${rowCount}, minmax(0, 1fr))`,
              gridTemplateColumns,
            }}
          >
            <Header
              showFootPath={showFootpath}
              multipleDirection={multipleDirection}
              showPlatform={showPlatform}
              departures={departures}
              intervalDepartureRow={intervalDepartureRow}
            />
            {departures !== undefined && departures !== null && (
              <Rows
                departures={departures.departures}
                notifications={departures.notifications}
                showFootPath={showFootpath}
                multipleDirection={multipleDirection}
                showPlatform={showPlatform}
                rowCount={rowCount}
                intervalDepartureRow={intervalDepartureRow}
                debugMode={debugMode}
              />
            )}
          </div>
        </div>
      </div>
    ) : departures?.departures !== undefined ? (
      <div
        ref={ref}
        className="relative flex flex-1 flex-col items-center justify-center overflow-hidden bg-zvvBlueDark text-title font-bold text-white"
      >
        {stationSelected ? t("no_departures") : t("choose_station")}
      </div>
    ) : departures === undefined ? (
      <div
        ref={ref}
        className="relative flex flex-1 flex-col items-center justify-center overflow-hidden bg-zvvBlueDark text-title font-bold text-white"
      >
        {t("no_data")}
      </div>
    ) : (
      <div
        ref={ref}
        className="relative flex flex-1 flex-col items-center justify-center overflow-hidden bg-zvvBlueDark text-title text-white"
      >
        <div className="flex h-screen items-center justify-center space-x-2  ">
          <div className="h-4 w-4 animate-bounce rounded-full bg-white [animation-delay:-0.3s]" />
          <div className="h-4 w-4 animate-bounce rounded-full bg-white [animation-delay:-0.15s]" />
          <div className="h-4 w-4 animate-bounce rounded-full bg-white" />
        </div>
      </div>
    );
  },
);
Table.displayName = "Table";
