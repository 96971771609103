import { NotificationType } from "@zvv-fkm/types/api";
import { AlertIcon } from "../table/rows/rowElements/AlertIcon";

export function Notification({
  text,
  type,
  alert,
  className,
}: {
  text: string;
  type: NotificationType;
  alert: boolean;
  className?: string;
}) {
  return (
    <div className={`my-5 flex w-full gap-4 ${className}`}>
      <div>
        <AlertIcon
          notificationType={type}
          className={`w-[calc(3rem*var(--row-scaling))] shrink-0 text-[calc(3.5rem*var(--row-scaling))] ${alert ? "text-white" : "text-black"}`}
        />
      </div>
      <div className="pt-[calc(0.4rem*var(--row-scaling))] ">
        <p className="font-bold">{text}</p>
      </div>
    </div>
  );
}
