
export interface Departure {
  walkingDistance: number;
  departureStationName: string;
  destinationStationName: string;
  lineName: string;
  color: string;
  textColor: string;
  transportTypeIcon: number;
  time: number;
  arrivalStatus: ArrivalStatus;
  delay: number;
  delayExact: number;
  platform: string;
  affectedByNotifications: string[];
  messageText: string;
  cancelled: boolean;
  wheelchairSymbol: AccessibilityLevel;
}

export enum AccessibilityLevel {
  ACCESSIBLE = "ACCESSIBLE", ASSISTED = "ASSISTED", ASSISTED_WITH_ANNOUNCEMENT = "ASSISTED_WITH_ANNOUNCEMENT", INACCESSIBLE = "INACCESSIBLE", UNKNOWN = "UNKNOWN", SHUTTLE = "SHUTTLE",
}

export enum ArrivalStatus {
  TRAVELLING = "TRAVELLING", AT_STATION = "AT_STATION", UNKNOWN = "UNKNOWN",
}
