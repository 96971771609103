import { Departure } from "@zvv-fkm/types";
import { DepartureNotification } from "@zvv-fkm/types/api";
import { IntervalDepartureRow } from "./departures/IntervalDepartureRow";
import { TimeBoundDepartureRow } from "./departures/TimeBoundDepartureRow";

const FillerRows = ({
  numberOfRows,
  keyPrefix = "",
}: {
  numberOfRows: number;
  keyPrefix: string;
}) => {
  const rows = Array.from({ length: numberOfRows }, (_, i) => (
    <div
      key={`${keyPrefix}-pos-${i}-filler-row`}
      className="col-span-full bg-zvvBlueDark even:bg-zvvBlue"
    />
  ));
  return rows;
};

export default function Rows({
  departures,
  notifications,
  showFootPath,
  multipleDirection,
  showPlatform,
  rowCount,
  intervalDepartureRow,
  debugMode,
}: {
  departures: Departure[];
  notifications: DepartureNotification[];
  showFootPath: boolean;
  multipleDirection: boolean;
  showPlatform: boolean;
  rowCount: number;
  intervalDepartureRow: boolean;
  debugMode: boolean;
}) {
  // truncate if we have more departures than we want to display
  const departuresToDisplay = departures.slice(0, rowCount);
  const leftOverRows = rowCount - departuresToDisplay.length;

  let howManyRowsUpdated = rowCount;
  departuresToDisplay.forEach((departure, i) => {
    if (howManyRowsUpdated > i && departure?.messageText) {
      howManyRowsUpdated--;
    }
  });

  const nextRowNeedsTwoRowsOfHeight =
    departuresToDisplay[howManyRowsUpdated]?.messageText;

  return (
    <>
      {departuresToDisplay.map((departure, i) =>
        howManyRowsUpdated > i ? (
          intervalDepartureRow ? (
            <IntervalDepartureRow
              key={`departure-${i}`}
              departure={departure}
              notifications={notifications}
              multipleDirection={multipleDirection}
              showFootPath={showFootPath}
              showPlatform={showPlatform}
              debugMode={debugMode}
            />
          ) : (
            <TimeBoundDepartureRow
              key={`departure-${i}`}
              departure={departure}
              notifications={notifications}
              multipleDirection={multipleDirection}
              showFootPath={showFootPath}
              showPlatform={showPlatform}
              debugMode={debugMode}
            />
          )
        ) : null,
      )}
      {nextRowNeedsTwoRowsOfHeight && (
        <FillerRows numberOfRows={1} keyPrefix="filler" />
      )}
      <FillerRows numberOfRows={leftOverRows} keyPrefix="leftovers" />
    </>
  );
}
