import { useNotificationContext } from "../../lib/hooks/Notifications";
import { Notification } from "./Notification";

export function NotificationsBannerCarousel() {
  const { notifications, currentNotificationIndex } = useNotificationContext();

  return (
    <>
      <div className="grid [&>*]:[grid-area:1/1]">
        {notifications.map((notification) => (
          <Notification
            key={notification.notificationId}
            className={`transition-opacity duration-500 ${
              notification.notificationId ===
              notifications[currentNotificationIndex].notificationId
                ? "opacity-100 delay-200"
                : "opacity-0 delay-0"
            }`}
            {...notification}
          />
        ))}
      </div>
      {notifications.length > 1 && (
        <div className="flex justify-center ">
          {notifications.map((notification, i) => (
            <div
              key={notification.notificationId}
              className={`mx-2 mb-6 h-5 w-5 rounded-full  ${i === currentNotificationIndex ? "bg-black" : "bg-lightGrey"} )`}
            />
          ))}
        </div>
      )}
    </>
  );
}
