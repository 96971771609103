import { useEffect } from "react";
import { VERSION_CHECK_INTERVAL } from "../constants";

const currentVersion: string = import.meta.env.VITE_APP_VERSION.trim();
const versionFilePath = import.meta.env.BASE_URL + "/version.txt";

export function useCheckAndUpdateVersion() {
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${versionFilePath}?ts=${Date.now()}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const version = (await response.text()).trim();
        if (version != currentVersion) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("version", version);
          location.replace(
            `${location.origin}${location.pathname}?${searchParams.toString()}${location.hash}`,
          );
        }
      } catch (e) {
        console.error(e);
      }
    }, VERSION_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, []);
}
