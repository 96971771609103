import { Config, DeparturesResponse } from "@zvv-fkm/types";
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { addBreadcrumb, captureException } from "@sentry/react";

export type WSOptions = {
  wsReconnectBackoffBaseMS: number;
  wsReconnectBackoffMaxMS: number;
  wsReconnectMaxTries: number;
  wsHeartbeatIntervalMS: number;
  wsHeartbeatTimeoutMS: number;
  wsHeartbeatMessageToServer: string;
  wsHeartbeatMessageFromServer: string;
};

export function useWebSocketDepartures(
  departuresConfig: Config,
  url: string,
  options: WSOptions,
) {
  const [lastPingPong, setLastPingPong] = useState(new Date(0));
  const [departureReceivedTimestamp, setDepartureReceivedTimestamp] = useState(
    new Date(0),
  );

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(url, {
    heartbeat: {
      interval: options.wsHeartbeatIntervalMS,
      timeout: options.wsHeartbeatTimeoutMS,
      message: options.wsHeartbeatMessageToServer,
    },
    filter: (event) => {
      setLastPingPong(new Date());
      if (event.data === options.wsHeartbeatMessageFromServer) {
        return false;
      }
      return true;
    },
    shouldReconnect: () => true,
    reconnectInterval: (attemptNumber: number) =>
      Math.min(
        Math.pow(2, attemptNumber) * options.wsReconnectBackoffBaseMS,
        options.wsReconnectBackoffMaxMS,
      ),
    reconnectAttempts: options.wsReconnectMaxTries,
    retryOnError: true,
  });

  useEffect(() => {
    // sending config on connect to "subscribe" to departures
    // will be queued if socket is not open yet
    if (readyState == ReadyState.CLOSED) {
      captureException("websocket closed");
    } else {
      addBreadcrumb({
        category: "websocket",
        message: "ReadyState " + readyState,
        level: "info",
      });
    }
    if (readyState !== ReadyState.OPEN) {
      return;
    }
    // subscribe to departures if config changed or connection established
    sendJsonMessage(departuresConfig);
  }, [departuresConfig, readyState]);

  useEffect(() => {
    setDepartureReceivedTimestamp(new Date());
  }, [lastJsonMessage]);

  return {
    departures: lastJsonMessage as DeparturesResponse | undefined,
    departureReceivedTimestamp,
    lastPingPong,
  };
}
