import { Config, DeparturesResponse } from "@zvv-fkm/types";
import { useCallback, useEffect, useState } from "react";
import { captureException } from "@sentry/react";

export type PollOptions = {
  pollingIntervalMS: number;
};

export function usePollDepartures(
  departuresConfig: Config,
  url: string,
  apiKey: string,
  enable: boolean,
  options: PollOptions,
) {
  const [departures, setDepartures] = useState(
    undefined as DeparturesResponse | undefined,
  );
  const [departureReceivedTimestamp, setDepartureReceivedTimestamp] = useState(
    new Date(0),
  );

  const fetchDepartures = useCallback(async () => {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(departuresConfig),
        headers: { "API-Key": apiKey, "Content-Type": "application/json" },
      });

      if (!response.ok) {
        captureException(
          "departure polling response nok: " + JSON.stringify(response),
        );
        throw new Error("Network response was not ok");
      }

      const decodedResponse = await response.json();
      setDepartures(decodedResponse);
      setDepartureReceivedTimestamp(new Date());
    } catch (e) {
      captureException("Departure Polling failed: " + e);
      console.error(e);
    }
  }, [setDepartures, url, departuresConfig, apiKey]);

  useEffect(() => {
    if (!enable) {
      return;
    }
    // load initial set of departures after being enabled
    fetchDepartures();
    // then switch to polling
    const interval = setInterval(async () => {
      fetchDepartures();
    }, options.pollingIntervalMS);
    return () => clearInterval(interval);
  }, [options.pollingIntervalMS, fetchDepartures, enable]);

  return { departures, departureReceivedTimestamp };
}
