export const DevBanner = ({
  version,
  env,
}: {
  version?: string;
  env: string;
}) => (
  <div className="absolute bottom-0 right-0 z-50 h-36 w-36 overflow-clip">
    <div className="absolute -bottom-24 -right-24 h-48 w-48 -rotate-45 bg-red p-2 text-center text-white">
      <p className="text-md font-bold ">{env.toUpperCase()}</p>
      {version && <p className="text-xs">{version}</p>}
    </div>
  </div>
);
