import { Departure } from "./departure";

export interface DeparturesResponse {
  stationTitle: string;
  notifications: DepartureNotification[];
  useIntervalTimes: boolean;
  hasKante: boolean;
  hasGleis: boolean;
  hasSteg: boolean;
  departures: Departure[];
}

export interface DepartureNotification {
  alert: boolean;
  notificationId: string;
  text: string;
  type: NotificationType;
}

export interface ActiveNotification {
  id: string;
  type: NotificationType | undefined;
}

export enum NotificationType {
  Construction = "Construction", Info = "Info", Event = "Event", Disruption = "Disruption", REPLACEMENT_SERVICE = "REPLACEMENT_SERVICE",
}

