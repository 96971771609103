import { Departure } from "@zvv-fkm/types";
import { DepartureNotification } from "@zvv-fkm/types/api";
import checkIsTicketMachine from "../../../../lib/hooks/CheckIsTicketMachine";
import { BaseRow } from "../BaseRow";
import LineCell from "../rowElements/LineCell";
import TimeCell from "../rowElements/TimeCell";

export function TimeBoundDepartureRow({
  departure,
  notifications,
  multipleDirection,
  showPlatform,
  showFootPath,
  debugMode,
}: {
  departure: Departure;
  notifications: DepartureNotification[];
  multipleDirection: boolean;
  showPlatform: boolean;
  showFootPath: boolean;
  debugMode: boolean;
}) {
  return (
    <BaseRow>
      <LineCell
        departure={departure}
        interval={false}
        type={departure.transportTypeIcon}
        lineName={departure.lineName}
        lineBgColor={departure.color}
        lineFgColor={departure.textColor}
        wheelchairAccessible={departure.wheelchairSymbol}
        affectedByNotification={departure.affectedByNotifications}
        notifications={notifications}
      />
      <TimeCell
        departure={departure}
        intervalDepartureRow={false}
        type={departure.transportTypeIcon}
        debugMode={debugMode}
      />
      <div className="flex">
        <span className="truncate pt-[calc(0.5rem*var(--row-scaling))]">
          {departure.destinationStationName}
        </span>
      </div>
      {multipleDirection && (
        <div className="flex">
          <span className="truncate pt-[calc(0.5rem*var(--row-scaling))]">
            {departure.departureStationName}
          </span>
        </div>
      )}
      {showFootPath && multipleDirection && (
        <div className="flex justify-center">
          <span className="pt-[calc(0.5rem*var(--row-scaling))]">
            {departure.walkingDistance}′
          </span>
        </div>
      )}
      {showPlatform && (
        <div className="flex justify-center">
          <span className="pt-[calc(0.5rem*var(--row-scaling))]">
            {departure.platform}
          </span>
        </div>
      )}
      {departure.messageText && (
        <div
          className={`col-span-full flex !pl-12 text-yellow ${checkIsTicketMachine() ? "text-rowTM" : "text-row"}`}
        >
          <span className="pt-[calc(0.5rem*var(--row-scaling))]">
            {departure.messageText}
          </span>
        </div>
      )}
    </BaseRow>
  );
}
