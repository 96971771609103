import { Departure } from "@zvv-fkm/types";
import { differenceInSeconds, getMinutes } from "date-fns";

export function isDeparting(departure: Departure) {
  const targetDate = departure.time;
  const currentDate = new Date();
  const minutesDifference = Math.ceil(
    differenceInSeconds(targetDate, currentDate) / 60,
  );
  return (
    minutesDifference + getMinutes(departure.delay) == 0 ||
    new Date(targetDate) <= currentDate
  );
}
