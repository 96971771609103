import { ActiveNotification, DepartureNotification } from "@zvv-fkm/types/api";
import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useInterval } from "usehooks-ts";
import { NOTIFICATION_INTERVAL } from "../constants";

export const NotificationContext = createContext<{
  notifications: DepartureNotification[];
  currentNotificationIndex: number;
  activeNotification: ActiveNotification | undefined;
  initialized: boolean;
  updateNotifications: Dispatch<DepartureNotification[]>;
}>({
  notifications: [],
  currentNotificationIndex: 0,
  activeNotification: undefined,
  initialized: false,
  updateNotifications: () => null,
});

export default function NotificationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [notifications, setNotifications] = useState<DepartureNotification[]>(
    [],
  );
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [activeNotification, setActiveNotification] =
    useState<ActiveNotification>();
  const [initialized, setInitialized] = useState(false);

  const ids = useMemo(() => Object.keys(notifications).sort(), [notifications]);
  const numberOfNotifications = ids.length;
  const stateTag = useMemo(() => ids.join("-"), [ids]);

  const updateNotifications = useCallback(
    (newNotifications: DepartureNotification[]) => {
      const uniqueNewNotifications = newNotifications
        .sort((a, b) => a.notificationId.localeCompare(b.notificationId))
        .filter(
          // deduplicate notifications by ids
          (notification, i, sortedNotifications) =>
            i === 0 ||
            notification.notificationId !==
              sortedNotifications[i - 1].notificationId,
        );

      !initialized && setInitialized(true);
      const newTag = uniqueNewNotifications
        .map((n) => n.notificationId)
        .join("-");
      if (stateTag === newTag) {
        // still the same notifications, no need to update
        return;
      }
      setNotifications(newNotifications);
    },
    [stateTag, initialized],
  );

  useInterval(() => {
    if (numberOfNotifications > 0) {
      setActiveNotification({
        id: notifications[
          (currentNotificationIndex + 1) % numberOfNotifications
        ].notificationId,
        type: notifications[
          (currentNotificationIndex + 1) % numberOfNotifications
        ].type,
      });
      setCurrentNotificationIndex(
        (currentNotificationIndex + 1) % numberOfNotifications,
      );
    }
  }, NOTIFICATION_INTERVAL);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        currentNotificationIndex:
          notifications.length > 0
            ? currentNotificationIndex % notifications.length
            : 0,
        activeNotification:
          activeNotification !== undefined
            ? activeNotification
            : notifications.length > 0
              ? {
                  id: notifications[0].notificationId,
                  type: notifications[0].type,
                }
              : undefined,
        initialized,
        updateNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
