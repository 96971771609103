import { TransportType } from "@zvv-fkm/types";
import { useMemo } from "react";
import "../../../..//assets/scss/_icons-ub.scss";
import "../../../../assets/scss/_icons-zvv.scss";

export const Icon = ({
  icon,
  className,
}: {
  icon: string;
  className?: string;
}) => {
  return <i className={`icon ${icon} ${className}`} />;
};

enum VehicleIconType {
  BOAT = "icon-zvv-boat",
  BUS = "icon-zvv-bus",
  CABLECAR = "icon-zvv-cablecar",
  FUNICULAR = "icon-zvv-funicular",
  TRAM = "icon-zvv-tram",
  TRAIN = "icon-zvv-train",
}

export const TransportTypeVehicleIcon = (props: {
  transportType: TransportType;
  className?: string;
}) => {
  const icon: VehicleIconType = useMemo(() => {
    switch (props.transportType) {
      case TransportType.UNKNOWN:
        return VehicleIconType.TRAM;
      case TransportType.RAILWAY:
        return VehicleIconType.TRAIN;
      case TransportType.BUS:
        return VehicleIconType.BUS;
      case TransportType.WATER_TRANSPORT:
        return VehicleIconType.BOAT;
      case TransportType.TRAM:
        return VehicleIconType.TRAM;
      case TransportType.AERIAL_LIFT:
        return VehicleIconType.CABLECAR;
      case TransportType.FUNICULAR:
        return VehicleIconType.FUNICULAR;
      default:
        // should never occur but if it does, 'tram' seems like the most reasonable fallback
        return VehicleIconType.TRAM;
    }
  }, [props.transportType]);

  return <Icon icon={icon} className={props.className} />;
};
