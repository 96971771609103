import { toZonedTime } from "date-fns-tz";
import { useEffect } from "react";

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export function useAutoReload(targetHour: IntRange<0, 24>) {
  useEffect(() => {
    const nowLocalTime = new Date();
    const nowZurich = toZonedTime(nowLocalTime, "Europe/Zurich");

    const targetZurich = new Date(nowZurich);
    targetZurich.setHours(targetHour, 0, 0, 0);

    if (targetZurich < nowZurich) {
      targetZurich.setDate(targetZurich.getDate() + 1);
    }

    const millisTillTargetTime = targetZurich.getTime() - nowZurich.getTime();

    console.log(
      "The application will auto-reload in",
      Math.round(millisTillTargetTime / 60 / 1000),
      "minutes.",
    );

    const timeout = setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("t", nowLocalTime.getTime().toString());
      location.replace(
        `${location.origin}${location.pathname}?${searchParams.toString()}${location.hash}`,
      );
    }, millisTillTargetTime);

    return () => clearTimeout(timeout);
  }, [targetHour]);
}
