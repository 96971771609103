export const Language = {
  DE: "de",
  EN: "en",
} as const;

export type Language = (typeof Language)[keyof typeof Language];

export interface Config {
  stations: StationConfig[];
  language: string;
  debugMode: boolean;
}
export interface StationConfig {
  stationID?: string;
  lines?: LineConfig[];
  walkingDistance?: number;
}

export interface LineConfig {
  lineId: string;
  directions: string[];
}
export type { DeparturesResponse } from "./api";
export type { Departure } from "./departure";

export interface DepartureLine {
  walkingDistance: 0;
  departureStationName: string;
  destinationStationName: string;
  lineName: string;
  color: string;
  textColor: string;
  transportTypeIcon: number;
  time: number;
  delay: number;
  delayExact: number;
  platform: string;
  messageText: string;
  wheelchairAccessible: boolean;
  cancelled: boolean;
}

export enum TransportType {
  UNKNOWN = 0,
  RAILWAY = 1,
  BUS = 2,
  WATER_TRANSPORT = 3,
  TRAM = 4,
  AERIAL_LIFT = 5,
  FUNICULAR = 6,
}