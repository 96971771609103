import { addBreadcrumb } from "@sentry/react";
import useDepartures from "@zvv-fkm/departures-hook";
import { DeparturesResponse } from "@zvv-fkm/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounceValue, useResizeObserver } from "usehooks-ts";
import Logo from "../assets/icons/logo_zvv.svg?react";
import { Table } from "../components/Table";
import { Clock } from "../components/clock";
import { NotificationBar } from "../components/notifications";
import { getScreenReaderText } from "../components/table/getScreenReaderText";
import { ConfigContext } from "../lib/contexts/ConfigContext";
import NotificationContextProvider from "../lib/contexts/NotificationsContext";
import checkIsTicketMachine from "../lib/hooks/CheckIsTicketMachine";

function computeAvailableRowCount(width: number, height: number): number {
  const rowHeight = Math.max((96 / 2208) * width, 1);
  return Math.floor((height - rowHeight) / rowHeight);
}

function getNumberOfRowsForTicketMachines(departures?: DeparturesResponse) {
  if (!!departures?.notifications && departures?.notifications.length >= 1) {
    return 8;
  } else {
    return 10;
  }
}

export default function DepartureScreen() {
  const { t, i18n } = useTranslation();
  const isTicketMachine = useMemo(() => checkIsTicketMachine(), []);
  // Note: all derivfiations from config that lead to the request config are (exessively) memorized on purpose to mitgate reloading of the departure hook
  const config = useMemo(() => {
    const type = window.location.hash.substring(1);
    const params = new URLSearchParams(window.location.search);
    if (isTicketMachine || params.get("debugMode") == "true") {
      //special case for ticket machines where we only add the stationID as config
      return {
        stations: [{ stationID: type }],
        language: "de",
        clock: "analog",
        showFootpath: "false",
        orderId: "SPOS",
        debugMode: params.get("debugMode") == "true",
      };
    } else {
      const decoded = atob(decodeURIComponent(type));
      return JSON.parse(decoded);
    }
  }, [isTicketMachine]);

  useEffect(() => {
    addBreadcrumb({
      category: "config",
      message: JSON.stringify(config),
      level: "info",
    });
  }, [config]);

  useEffect(() => {
    i18n.changeLanguage(config.language);
  }, []);

  const multipleDirection = config.stations.length > 1;

  const refRowCountToDisplay = useRef<HTMLDivElement>(null);
  const [measuredRowCountToDisplay, setMeasuredRowCountToDisplay] =
    useState(10);
  const [rowCountToDisplay] = useDebounceValue(measuredRowCountToDisplay, 100);
  const { width: widthTable = 0, height: heightTable = 0 } = useResizeObserver({
    ref: refRowCountToDisplay,
  });

  useEffect(() => {
    const rowCount = computeAvailableRowCount(widthTable, heightTable);
    setMeasuredRowCountToDisplay(rowCount);
  }, [widthTable, heightTable, setMeasuredRowCountToDisplay]);

  /**
   * NOTE: Technically, we only need rowCountToDisplay; however, if we get departures with notifications, this size changes which -> another request
   * We can imagine a worst case scenario where the last departure of the list is the only one with a notification. If we now render this notification,
   * the size of the table changes which drops this one notification. We now send another request for one row less. The response to this will no longer
   * contain this departure with the notification which gives us room for one more departure as we don't need to render a notification...
   */
  const refRowCountToRequest = useRef<HTMLDivElement>(null);
  const [measuredRowCountToRequest, setMeasuredRowCountToRequest] =
    useState(10);
  const [rowCountToRequest] = useDebounceValue(measuredRowCountToRequest, 500); // debouncing significantly, better send fewer requests than refreshing too quickly
  const {
    width: widthTableInclNotifications = 0,
    height: heightTableInclNotifications = 0,
  } = useResizeObserver({
    ref: refRowCountToRequest,
  });

  useEffect(() => {
    const rowCount = computeAvailableRowCount(
      widthTableInclNotifications,
      heightTableInclNotifications,
    );
    setMeasuredRowCountToRequest(rowCount);
  }, [widthTableInclNotifications, heightTableInclNotifications]);

  const { showFootpath, clock, requestConfig } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { showFootpath, clock, landscape, ...requestConfig } = config;
    requestConfig.numberOfResults =
      rowCountToRequest < 10 || isTicketMachine ? 10 : rowCountToRequest;
    return { showFootpath, clock, requestConfig };
  }, [isTicketMachine, config, rowCountToRequest]);

  const {
    departures,
    wsReceivedTimestamp, // used for ws test and "zuletzt aktualisiert aria label"
    wsLastPingPong, // only for ws test
    pollReceivedTimestamp, // used for ws test and "zuletzt aktualisiert aria label"
    showingDeparturesFrom, // only for ws test
  } = useDepartures(
    requestConfig,
    import.meta.env.VITE_APP_VIADI_WSS_URL,
    import.meta.env.VITE_APP_VIADI_DEPARTURES_URL,
    import.meta.env.VITE_APP_VIADI_API_KEY,
  );

  // notify SPOS iframe-parent with postMessage about "all changes"
  // string made up of the screen reader texts for all displayed departures
  // and the last updated time
  useEffect(() => {
    if (!isTicketMachine || !departures) {
      return;
    }
    let postText = departures?.departures
      .slice(0, getNumberOfRowsForTicketMachines(departures))
      .map((departure) => {
        return getScreenReaderText(
          departure,
          departures.notifications,
          departures.useIntervalTimes,
        ).replace(/\n/g, " ");
      })
      .join("\n");

    postText +=
      "\n" + t("updated_at") + " " + new Date().toLocaleTimeString("de-ch");

    window.parent.postMessage(postText, "*");
  }, [isTicketMachine, departures, t]);

  const intervalDepartureRow = departures?.useIntervalTimes ?? true;

  // only for ws test
  const showWsTestInfo = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.has("wsTest");
  }, []);

  const wsTestProps = {
    wsReceivedTimestamp,
    wsLastPingPong,
    pollReceivedTimestamp,
    showingDeparturesFrom,
  };

  const screenReaderText =
    t("next_departures") +
    (!multipleDirection && " " + departures?.stationTitle);

  return (
    <ConfigContext.Provider value={config}>
      <NotificationContextProvider>
        <div className="flex h-screen w-full gap-1">
          {
            // ONLY FOR TEST
            showWsTestInfo && (
              <WsTest
                {...wsTestProps}
                className="absolute right-[calc(31rem*var(--header-scaling))] bg-white bg-opacity-85"
              />
            )
          }
          <div className="bg-primary flex h-screen w-full flex-col">
            {!isTicketMachine && (
              <header>
                <div
                  className={`flex items-center justify-between gap-9 bg-white p-4 text-white `}
                  aria-label={screenReaderText}
                  tabIndex={0}
                >
                  <div className="flex gap-5">
                    <Clock type={clock} />
                    {departures?.departures !== null &&
                      departures?.departures !== undefined &&
                      departures !== undefined &&
                      departures !== null &&
                      departures?.departures.length > 0 && (
                        <div
                          className={`flex h-[calc(9rem*var(--header-scaling))] items-center truncate text-title font-bold text-black ${clock === "" && "ml-4"}`}
                        >
                          {multipleDirection
                            ? t("next_departures")
                            : `${departures.stationTitle}`}
                        </div>
                      )}
                  </div>
                  <Logo className="h-[calc(5rem*var(--header-scaling))] w-[calc(30rem*var(--header-scaling))]" />
                </div>
              </header>
            )}
            <div
              className="flex h-full w-full flex-col overflow-hidden"
              ref={refRowCountToRequest}
            >
              <Table
                ref={refRowCountToDisplay}
                departures={departures}
                showFootpath={showFootpath}
                multipleDirection={multipleDirection}
                rowCount={
                  isTicketMachine
                    ? getNumberOfRowsForTicketMachines(departures)
                    : rowCountToDisplay
                }
                intervalDepartureRow={intervalDepartureRow}
                stationSelected={config.stations.length >= 1}
                debugMode={config.debugMode}
              />
              {departures !== undefined && (
                <NotificationBar departures={departures} />
              )}
              <div
                className="h-0"
                aria-label={
                  t("updated_at") + " " + new Date().toLocaleTimeString("de-ch")
                }
              ></div>
            </div>
          </div>
        </div>
      </NotificationContextProvider>
    </ConfigContext.Provider>
  );
}

// ONLY FOR TEST
function TimestampInfoBox(props: { title: string; timestamp: Date }) {
  const { title, timestamp } = props;
  return (
    <div className="m-2 flex flex-col items-center gap-2">
      <h2 className="text-row">{title}</h2>
      <p className="text-row font-bold">
        {timestamp > new Date(0) ? timestamp.toLocaleTimeString() : "Not Set"}
      </p>
    </div>
  );
}

// ONLY FOR TEST
function WsTest(props: {
  wsReceivedTimestamp: Date;
  wsLastPingPong: Date;
  pollReceivedTimestamp: Date;
  showingDeparturesFrom: string;
  className: string;
}) {
  const {
    wsReceivedTimestamp,
    wsLastPingPong,
    pollReceivedTimestamp,
    showingDeparturesFrom,
    className,
  } = props;

  return (
    <div className={`text-red-800 flex flex-col items-center ${className}`}>
      <div className="flex">
        <TimestampInfoBox
          title="Poll Received"
          timestamp={pollReceivedTimestamp}
        />
        <TimestampInfoBox title="WS Received" timestamp={wsReceivedTimestamp} />
        <TimestampInfoBox
          title="WS Last Ping Pong"
          timestamp={wsLastPingPong}
        />
      </div>
      <h1 className="text-row">
        Showing departures from:{" "}
        <span className="font-bold">{showingDeparturesFrom}</span>
      </h1>
    </div>
  );
}
