import { DeparturesResponse, Language } from "@zvv-fkm/types";
import { useTranslation } from "react-i18next";
import checkIsTicketMachine from "../lib/hooks/CheckIsTicketMachine";

interface TranslationResources {
  [key: string]: {
    translation: {
      [key: string]: string;
    };
  };
}

export default function Header({
  showFootPath,
  multipleDirection,
  showPlatform,
  departures,
  intervalDepartureRow,
}: {
  showFootPath: boolean;
  multipleDirection: boolean;
  showPlatform: boolean;
  departures: DeparturesResponse | undefined;
  intervalDepartureRow: boolean;
}) {
  const { t, i18n } = useTranslation();

  function getHeaderTitle(): string | JSX.Element {
    const isTrain = departures?.hasGleis;
    const isBus = departures?.hasKante;
    const isShip = departures?.hasSteg;
    if (checkIsTicketMachine()) {
      if (isTrain) {
        return getTitle("platform_train");
      } else if (isBus) {
        return getTitle("platform_bus");
      } else if (isShip) {
        return getTitle("platform_ship");
      }
    }
    return (
      (isTrain ? t("platform_train") : "") +
      (isBus ? (isTrain ? `/${t("platform_bus")}` : t("platform_bus")) : "") +
      (isShip
        ? isBus || isTrain
          ? `/${t("platform_ship")}`
          : t("platform_ship")
        : "")
    );
  }

  const storeData = i18n.store.data as TranslationResources;

  function getTitle(translationKey: string) {
    if (checkIsTicketMachine()) {
      return (
        <div className="flex flex-col">
          <span className="inline-block text-left">{t(translationKey)}</span>
          <span className="text-left text-[1.75rem]">
            {storeData[Language.EN].translation[translationKey]}
          </span>
        </div>
      );
    } else {
      return t(translationKey);
    }
  }

  return (
    <>
      {intervalDepartureRow ? (
        <div
          className={`contents font-bold text-white [&>div]:bg-darkGrey   ${checkIsTicketMachine() ? "text-headerTM [&>div]:!pt-5" : "text-header"}`}
        >
          <div className="flex">
            <span
              className={`pt-[calc(0.5rem*var(--row-scaling))] ${checkIsTicketMachine() ? "pr-5" : ""}`}
            >
              {getTitle("table_line")}
            </span>
          </div>
          <div className="flex">
            <span className="pt-[calc(0.5rem*var(--row-scaling))]">
              {getTitle("table_direction")}
            </span>
          </div>
          {multipleDirection && (
            <div className="flex">
              <span className="pt-[calc(0.5rem*var(--row-scaling))]">
                {getTitle("table_from")}
              </span>
            </div>
          )}
          {showFootPath && multipleDirection && (
            <div className="flex">
              <span className="pt-[calc(0.5rem*var(--row-scaling))]">
                {getTitle("table_footpath")}
              </span>
            </div>
          )}
          {showPlatform && (
            <div className="flex">
              <span
                className={`pt-[calc(0.5rem*var(--row-scaling))] ${checkIsTicketMachine() ? "pr-5" : ""}`}
              >
                {getHeaderTitle()}
              </span>
            </div>
          )}
          <div className="flex">
            <span className="pt-[calc(0.5rem*var(--row-scaling))]">
              {getTitle("table_departure")}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={`contents font-bold text-white [&>div]:bg-darkGrey  ${checkIsTicketMachine() ? "text-headerTM [&>div]:!pt-5" : "text-header"}`}
        >
          <div className="flex">
            <span className="pt-[calc(0.5rem*var(--row-scaling))]">
              {getTitle("table_line")}
            </span>
          </div>
          <div className="flex">
            <span
              className={`pt-[calc(0.5rem*var(--row-scaling))] ${checkIsTicketMachine() ? "pr-5" : ""}`}
            >
              {getTitle("table_departure")}
            </span>
          </div>
          <div className="flex">
            <span className="pt-[calc(0.5rem*var(--row-scaling))]">
              {getTitle("table_direction")}
            </span>
          </div>
          {multipleDirection && (
            <div className="flex">
              <span className="pt-[calc(0.5rem*var(--row-scaling))]">
                {getTitle("table_from")}
              </span>
            </div>
          )}
          {showFootPath && multipleDirection && (
            <div className="flex">
              <span className="pt-[calc(0.5rem*var(--row-scaling))]">
                {getTitle("table_footpath")}
              </span>
            </div>
          )}
          {showPlatform && (
            <div className="flex">
              <span className="pt-[calc(0.5rem*var(--row-scaling))]">
                {getHeaderTitle()}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
