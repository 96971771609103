/* eslint-disable react-hooks/rules-of-hooks */
import { TransportType } from "@zvv-fkm/types";
import { DepartureNotification } from "@zvv-fkm/types/api";
import {
  AccessibilityLevel,
  ArrivalStatus,
  Departure,
} from "@zvv-fkm/types/departure";
import { differenceInSeconds, format, getMinutes } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import i18n from "../../../i18n";
import { isDeparting } from "../../../lib/helpers";

function getNotificationText(
  notificationId: string | undefined,
  notifications: DepartureNotification[],
): string | undefined {
  return notifications.find((n) => n.notificationId === notificationId)?.text;
}

// TODO: refactor to read out all info out of departure
export const getScreenReaderText = (
  departure: Departure,
  notifications: DepartureNotification[],
  intervalDepartureRow: boolean,
): string => {
  const t = i18n.t;
  const nowDeparting =
    departure.arrivalStatus === ArrivalStatus.AT_STATION ||
    isDeparting(departure);
  const showPlatform = departure.platform != null;
  const isTrain = departure.transportTypeIcon === 1;

  const affectedNotificationsForScreenReader = departure.affectedByNotifications
    .filter((notificationId) => {
      return !notificationId.endsWith("_en");
    })
    .map((notificationId) => {
      return getNotificationText(notificationId, notifications);
    });

  let screenReaderText = "";

  const exactDepartureTime = departure.time + departure.delayExact;
  const timeIn30min = new Date();
  timeIn30min.setMinutes(timeIn30min.getMinutes() + 30);

  if (departure.cancelled) {
    return getCancelledText(isTrain, departure);
  } else if (
    intervalDepartureRow &&
    new Date(exactDepartureTime) <= timeIn30min
  ) {
    if (nowDeparting) {
      screenReaderText = getIntervalAndNowText(
        departure,
        showPlatform,
        isTrain,
      );
    } else {
      screenReaderText = getIntervalText(departure, showPlatform, isTrain);
    }
  } else {
    screenReaderText = getTimeBoundText(departure, showPlatform, isTrain);
  }

  return `${screenReaderText}.  ${departure.wheelchairSymbol === AccessibilityLevel.ACCESSIBLE ? t("text_wheelchair_accessible") : ""} ${departure.wheelchairSymbol === AccessibilityLevel.ASSISTED ? t("text_wheelchair_assisted") : ""} ${departure.messageText != null ? departure.messageText : ""} ${affectedNotificationsForScreenReader.length > 0 ? affectedNotificationsForScreenReader : ""} ... `;
};

const getIntervalText = (
  departure: Departure,
  platform: boolean,
  isTrain: boolean,
): string => {
  const t = i18n.t;

  const targetDate = new Date(departure.time);
  const currentDate = new Date();
  const minutesDifference = Math.ceil(
    differenceInSeconds(targetDate, currentDate) / 60,
  );

  return `${isTrain ? "" : t("table_line")} ${departure.lineName}
        ${t("text_in_direction")} ${departure.destinationStationName}
        ${t("in_minutes_text")} ${minutesDifference + getMinutes(departure.delay)} ${t("text_minutes")}
        ${platform ? `${getType(departure.transportTypeIcon)} ${replaceNumberWithString(departure.platform)}` : ""}`;
};

const getIntervalAndNowText = (
  departure: Departure,
  platform: boolean,
  isTrain: boolean,
): string => {
  const t = i18n.t;

  return `${isTrain ? "" : t("table_line")} ${departure.lineName} 
        ${t("text_in_direction")}  ${departure.destinationStationName}
        ${t("text_departs_now")} 
        ${platform ? `${getType(departure.transportTypeIcon)} ${replaceNumberWithString(departure.platform)}` : ""} `;
};

const getTimeBoundText = (
  departure: Departure,
  platform: boolean,
  isTrain: boolean,
): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const t = i18n.t;
  const date = new Date(departure.time);
  const zurichTime = toZonedTime(date, "Europe/Zurich");

  return `${isTrain ? "" : t("table_line")} ${departure.lineName} 
        ${t("text_in_direction")} ${departure.destinationStationName}
         ${t("text_at")} ${format(zurichTime, "HH:mm")} ${t("text_clock")}
        ${departure.delay > 0 ? t("text_with_about") + getMinutes(departure.delay) + t("text_minutes") + t("text_delayed") : ""}  ${platform ? `${getType(departure.transportTypeIcon)} ${replaceNumberWithString(departure.platform)}` : ""} `;
};

const getCancelledText = (isTrain: boolean, departure: Departure): string => {
  const t = i18n.t;
  const date = new Date(departure.time);
  const zurichTime = toZonedTime(date, "Europe/Zurich");

  return `${isTrain ? "" : t("table_line")} ${departure.lineName} 
        ${t("text_in_direction")}  ${departure.destinationStationName}
         ${t("text_at")} ${format(zurichTime, "HH:mm")} ${t("text_clock")}
        ${t("text_cancelled")}`;
};

function getType(type: TransportType) {
  const t = i18n.t;
  switch (type) {
    case TransportType.UNKNOWN:
    case TransportType.RAILWAY:
      return t("text_train");
    case TransportType.BUS:
      return t("text_bus");
    case TransportType.WATER_TRANSPORT:
      return +t("text_ship");
    case TransportType.TRAM:
      return t("text_bus");
    case TransportType.AERIAL_LIFT:
      return t("text_ship");
    case TransportType.FUNICULAR:
      return t("text_ship");
    default:
      return t("text_bus");
  }
}

function replaceNumberWithString(text: string) {
  text = text.replace("10", "zehn");
  text = text.replace("11", "elf");
  text = text.replace("12", "zwölf");
  text = text.replace("13", "dreizehn");
  text = text.replace("14", "vierzehn");
  text = text.replace("15", "fünfzehn");
  text = text.replace("16", "sechzehn");
  text = text.replace("17", "siebzehn");
  text = text.replace("18", "achtzehn");
  text = text.replace("19", "neunzehn");
  text = text.replace("20", "zwanzig");
  text = text.replace("21", "einundzwanzig");
  text = text.replace("22", "zweiundzwanzig");
  text = text.replace("23", "dreiundzwanzig");
  text = text.replace("24", "vierundzwanzig");
  text = text.replace("30", "dreissig");
  text = text.replace("31", "einunddreissig");
  text = text.replace("32", "zweiunddreissig");
  text = text.replace("33", "dreiunddreissig");
  text = text.replace("34", "vierunddreissig");
  text = text.replace("40", "vierzig");
  text = text.replace("41", "einundvierzig");
  text = text.replace("42", "zweiundvierzig");
  text = text.replace("43", "dreiundvierzig");
  text = text.replace("44", "vierundvierzig");
  text = text.replace("1", "eins");
  text = text.replace("2", "zwei");
  text = text.replace("3", "drei");
  text = text.replace("4", "vier");
  text = text.replace("5", "fünf");
  text = text.replace("6", "sechs");
  text = text.replace("7", "sieben");
  text = text.replace("8", "acht");
  text = text.replace("9", "neun");
  return text;
}
