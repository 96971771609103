import { ReactNode } from "react";
import checkIsTicketMachine from "../../../lib/hooks/CheckIsTicketMachine";

export const BaseRow = ({ children }: { children?: ReactNode }) => {
  return (
    <div
      className={`contents font-bold text-white [&:nth-child(2n)_div]:bg-zvvBlue [&_div]:bg-zvvBlueDark ${checkIsTicketMachine() ? "text-rowTM" : "text-row"}`}
    >
      {children}
    </div>
  );
};
