import React, { Suspense } from "react";
import { ClockType } from ".";

const DigitalClock = React.lazy(() => import("./DigitalClock"));
const SBBClock = React.lazy(() => import("./sbb-clock/SBBClock"));

export function Clock({ type }: { type: ClockType }) {
  let ClockComponent: React.ComponentType<unknown>;

  switch (type) {
    case ClockType.DIGITAL:
      ClockComponent = DigitalClock;
      break;
    case ClockType.ANALOG:
      ClockComponent = SBBClock;
      break;
    default:
      return null;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ClockComponent />
    </Suspense>
  );
}
