export const NOTIFICATION_INTERVAL =
  (import.meta.env.VITE_PUBLIC_ENV === "local" ? 6 : 15) * 1000;
export const VERSION_CHECK_INTERVAL = 10 * 60 * 1000;

export const defaultConfig = {
  stations: [],
  landscape: true,
  language: "de",
  clock: "analog",
  showFootpath: true,
};
