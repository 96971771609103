import { DeparturesResponse } from "@zvv-fkm/types/api";
import { useEffect } from "react";
import checkIsTicketMachine from "../../lib/hooks/CheckIsTicketMachine";
import { useNotificationContext } from "../../lib/hooks/Notifications";
import { NotificationsBannerCarousel } from "./NotificationsBannerCarousel";

export function NotificationBar({
  departures,
}: {
  departures: DeparturesResponse;
}) {
  const {
    updateNotifications,
    notifications,
    currentNotificationIndex,
    initialized,
  } = useNotificationContext();

  useEffect(() => {
    if (
      departures &&
      departures.notifications &&
      departures.notifications.length > 0
    ) {
      updateNotifications(departures.notifications);
    } else {
      // clear notifications
      updateNotifications([]);
    }
  }, [departures, updateNotifications]);

  return (
    // 'initialized' guards on 'updateNotifications' not being called yet
    initialized &&
    notifications.length > 0 && (
      <footer
        className={`flex flex-col justify-between overflow-hidden px-8 transition-colors duration-700 ${notifications[currentNotificationIndex].alert ? "bg-red text-white" : "bg-white"} ${checkIsTicketMachine() ? "text-event-messages-TM" : "text-event-messages"}`}
      >
        <NotificationsBannerCarousel />
      </footer>
    )
  );
}
