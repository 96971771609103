export const getGridTemplateColumns = (
  intervalDepartureRow: boolean,
  showPlatform: boolean,
  showFootPath: boolean,
  multipleDirection: boolean,
): string => {
  if (intervalDepartureRow) {
    return getIntervalDeparturesGridTemplateColumns(
      showPlatform,
      showFootPath,
      multipleDirection,
    );
  } else {
    return getTimeBoundDeparturesGridTemplateColumns(
      showPlatform,
      showFootPath,
      multipleDirection,
    );
  }
};

const getIntervalDeparturesGridTemplateColumns = (
  showPlatform: boolean,
  showFootPath: boolean,
  multipleDirection: boolean,
): string =>
  Object.values({
    lines: "auto",
    direction: "1fr",
    placeOfDeparture: multipleDirection ? "1fr" : "",
    walkingDistance: showFootPath && multipleDirection ? "auto" : "",
    platform: showPlatform ? "auto" : "",
    departure: "min-content",
  })
    .filter(Boolean)
    .join(" ");

const getTimeBoundDeparturesGridTemplateColumns = (
  showPlatform: boolean,
  showFootPath: boolean,
  multipleDirection: boolean,
): string =>
  Object.values({
    lines: "auto",
    departure: "auto",
    direction: "1fr",
    placeOfDeparture: multipleDirection ? "1fr" : "",
    walkingDistance: showFootPath && multipleDirection ? "auto" : "",
    platform: showPlatform ? "auto" : "",
  })
    .filter(Boolean)
    .join(" ");
