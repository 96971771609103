import { useEffect } from "react";
import { DevBanner } from "./DevBanner";
import DepartureScreen from "./containers/DepartureScreen";
import { useAutoReload } from "./lib/hooks/AutoReload";
import { useCheckAndUpdateVersion } from "./lib/hooks/CheckAndUpdateVersion";

export default function App() {
  const version = import.meta.env.VITE_APP_VERSION;
  const env = import.meta.env.MODE;

  useCheckAndUpdateVersion();
  useAutoReload(4); // reload at 4am

  useEffect(() => {
    window.addEventListener("dragstart", (e) => e.preventDefault());
    return () => {
      window.removeEventListener("dragstart", (e) => e.preventDefault());
    };
  }, []);

  return (
    <main className=" h-screen w-full select-none">
      <DepartureScreen />
      {env !== "zvv-prod" && <DevBanner version={version} env={env} />}
    </main>
  );
}
