import checkIsTicketMachine from "../../../../lib/hooks/CheckIsTicketMachine";

interface LineBadgeProps {
  lineName: string; // NOTE: do we need to handle anything longer than 3 chars?
  lineFgColor: string;
  lineBgColor: string;
  disabled: boolean;
}

export const LineBadge = ({
  lineName,
  lineBgColor,
  lineFgColor,
  disabled,
}: LineBadgeProps) => {
  return (
    <span
      className={`grid place-content-center place-items-center rounded-md py-1 leading-none ${checkIsTicketMachine() ? "text-rowTM h-[calc(4rem*var(--row-scaling))] w-[calc(8rem*var(--row-scaling))]" : "h-[calc(3rem*var(--row-scaling))] w-[calc(7rem*var(--row-scaling))] text-row"}`}
      style={{
        backgroundColor: `#${lineBgColor}`,
        color: `#${lineFgColor}`,
        opacity: disabled ? "80%" : "100%",
      }}
    >
      <p
        className={`${lineName.length > 5 && "text-rowSmall"}  ${lineName.length > 8 && "!text-rowXSmall"} ${checkIsTicketMachine() ? "pt-[calc(0.6rem*var(--row-scaling))]" : "pt-[calc(0.4rem*var(--row-scaling))]"}`}
      >
        {lineName}
      </p>
    </span>
  );
};
