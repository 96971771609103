import { AccessibilityLevel } from "@zvv-fkm/types/departure";
import { useMemo } from "react";
import "../../../..//assets/scss/_icons-ub.scss";
import "../../../../assets/scss/_icons-zvv.scss";

export const Icon = ({
  icon,
  className,
}: {
  icon: string;
  className?: string;
}) => {
  return <i className={`icon ${icon} ${className}`} />;
};

export const AccessibilityLevelIcon = (props: {
  accessibility: AccessibilityLevel;
  className?: string;
}) => {
  const icon: string = useMemo(() => {
    switch (props.accessibility) {
      case AccessibilityLevel.ACCESSIBLE:
        return "icon-ub-wheelchair";
      case AccessibilityLevel.ASSISTED:
        return "icon-ub-wheelchair-clamped";
      case AccessibilityLevel.UNKNOWN:
      case AccessibilityLevel.ASSISTED_WITH_ANNOUNCEMENT:
      case AccessibilityLevel.SHUTTLE:
      case AccessibilityLevel.INACCESSIBLE:
      default:
        return "";
    }
  }, [props.accessibility]);

  return <Icon icon={icon} className={props.className} />;
};
